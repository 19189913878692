<template>
	<div class="m1">
		<div class="hd ">
			<!-- <div class="h1">
				<el-breadcrumb separator="/" style="margin-top: 1.5rem;">
					<el-breadcrumb-item :to="{ path: '/gjsz' }"><span
							style="color: rgba(43, 222, 201, 0.4);">公司列表</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item><span style="color: rgba(43, 222, 201, .4);">{{projectName}}</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item><span style="color: rgba(43, 222, 201, .4);">{{projectName1}}</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item><span style="color: rgba(43, 222, 201, .4);">{{projectName2}}</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item><span style="color: rgba(43, 222, 201, .4);">设备列表</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item><span style="color: rgba(43, 222, 201, 1);">告警设置</span></el-breadcrumb-item>
				</el-breadcrumb>
			</div> -->
			<div class="back" @click="back()">
				返回
			</div>
		</div>
		<div class="hd" style="margin-top: 1rem;">
			<div class="h1">
				告警设置
			</div>
			<div class="add-notic-person" @click="edhj()">
				保存设置
			</div>
		</div>
		<!-- 列表 -->
		<div class="lb">
			<div v-if="this.type == 0">
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[0].name }}
						</div>
						<div class="hd_2_2" v-if="this.type == 0">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.gasStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;" v-model="Hjdata.gasEnd"
									value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[1].name }}
						</div>
						<div class="hd_2_2" v-if="this.type == 0">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.temperatureStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.temperatureEnd" value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[2].name }}
						</div>
						<div class="hd_2_2" v-if="this.type == 0">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.humidityStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.humidityEnd" value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[3].name }}
						</div>
						<div class="hd_2_2" v-if="this.type == 0">
							<span style="margin-right: 1rem;"> 关闭</span>
							<el-switch v-model="Hjdata.vibrationStart" active-color="#55aa7f" inactive-color="#e8595b"
								:active-value='Number(1)' :inactive-value='Number(0)'>
							</el-switch>
							<span style="margin-left: 1rem;">开启</span>
						</div>

					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[4].name }}
						</div>
						<div class="hd_2_2" v-if="this.type == 0">
							<span style="margin-right: 1rem;"> 关闭</span>
							<el-switch v-model="Hjdata.waterLevelStart" active-color="#55aa7f" inactive-color="#e8595b"
								:active-value='Number(1)' :inactive-value='Number(0)'>
							</el-switch>
							<span style="margin-left: 1rem;">开启</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[5].name }}
						</div>
						<div class="hd_2_2" v-if="this.type == 0">
							<span style="margin-right: 1rem;"> 关闭</span>
							<el-switch v-model="Hjdata.faultStart" active-color="#55aa7f" inactive-color="#e8595b"
								:active-value='Number(1)' :inactive-value='Number(0)'>
							</el-switch>
							<span style="margin-left: 1rem;">开启</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[6].name }}
						</div>
						<div class="hd_2_2" v-if="this.type == 0">
							<span style="margin-right: 1rem;"> 关闭</span>
							<el-switch v-model="Hjdata.fireWarn" active-color="#55aa7f" inactive-color="#e8595b"
								:active-value='Number(1)' :inactive-value='Number(0)'>
							</el-switch>
							<span style="margin-left: 1rem;">开启</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							采集间隔时间
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.acquisitionInterval" value="" />
							</span>
							分钟
						</div>
					</span>
				</div>
			</div>
			<div v-if="this.type == 1">
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[0].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.uaStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;" v-model="Hjdata.uaEnd"
									value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[1].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.ubStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;" v-model="Hjdata.ubEnd"
									value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[2].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.ucStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;" v-model="Hjdata.ucEnd"
									value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[3].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.iaStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;" v-model="Hjdata.iaEnd"
									value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[4].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.ibStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;" v-model="Hjdata.ibEnd"
									value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[5].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.icStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;" v-model="Hjdata.icEnd"
									value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[6].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.epNetStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.epNetEnd" value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[7].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.eqNetStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.eqNetEnd" value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[8].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.pfStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;" v-model="Hjdata.pfEnd"
									value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[9].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.psumStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.psumEnd" value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[10].name }}
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.qsumStart" value="" />
							</span>
							至
							<span class="d2">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.qsumEnd" value="" />
							</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							{{ list[11].name }}
						</div>
						<div class="hd_2_2">
							<span style="margin-right: 1rem;"> 关闭</span>
							<el-switch v-model="Hjdata.swtNotice" active-color="#55aa7f" inactive-color="#e8595b"
								:active-value='Number(1)' :inactive-value='Number(0)'>
							</el-switch>
							<span style="margin-left: 1rem;">开启</span>
						</div>
					</span>
				</div>
				<div class="hd">
					<span class="hd_2" style="position: relative;">
						<div class="hd_2_1">
							采集间隔时间
						</div>
						<div class="hd_2_2">
							<span class="d1">
								<input style="width: 10rem; color: #FFFFFF; text-align: center;"
									v-model="Hjdata.acquisitionInterval" value="" />
							</span>
							分钟
						</div>
					</span>
				</div>
			</div>


			<!-- 主体 -->
			<div style="position: relative; padding-bottom: 1rem;">
				<div class="hd1" style="margin-top: 1rem;">
					<div class="h1" style="float: left; color: #FFFFFF;">
						告警人员
					</div>
					<div class="add-notic-person" @click="Adgj()">
						新增警告人员
					</div>
				</div>
				<div class="main" style="">
					<div style="width: 100%;display: flex; margin-bottom: 1rem;" v-for="(item, index) in FData"
						:key="index">
						<div class="m_2" style="width: 100%; border: none; background-color: rgba(43, 222, 201, 0.6);">
							<div style="margin-left: 1rem;">
								{{ item.personnel }}
							</div>
							<div style="position: absolute; top: 50%; right: 3rem; transform: translateY(-50%); width: 3rem; height: 3rem; cursor: pointer;"
								@click="del(item.id)">
								<img style="width: 3rem; height: 3rem;" src="../../static/sc.png">
							</div>
							<div
								style="position: absolute; top: 50%; right: 8rem; transform: translateY(-50%); width: 3rem; height: 3rem; cursor: pointer;">
								<img style="width: 3rem; height: 3rem;" src="../../static/xg.png" @click="Edgj(item)">
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>


		<!-- 新增弹窗 -->
		<el-dialog title="新增账号" :visible.sync="dialogFormVisible">
			<el-form :model="form">
				<el-form-item label="姓名:" :label-width="formLabelWidth">
					<el-input v-model="form.personnel" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="账号:" :label-width="formLabelWidth">
					<el-input v-model="form.phone" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button v-if="newad" type="primary" @click="Form()">确 定</el-button>
				<el-button v-if="!newad" type="primary" @click="Form1()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'gsgly',
	data() {
		return {
			list: [{
				name: 'A相电压告警'
			}
			],
			value: true,
			currentPage4: 1,
			type: null,
			id: null,
			dialogFormVisible: false,
			form: {
				"personnel": '',
				"phone": ''
			},
			formLabelWidth: '14rem',
			FData: [],
			newad: true,
			Hjdata: {},
			projectName: '',
			projectName1: '',
			projectName2: ''
		}
	},
	created() {
		this.fdry()

		let arr1 = decodeURIComponent(this.$route.query.projectName)

		this.projectName = arr1.projectName
		this.projectName1 = arr1.projectName1
		this.projectName2 = arr1.projectName2
		this.id = this.$route.query.id
		this.type = this.$route.query.type
		if (this.type == 0) {
			this.list = [{
				name: '气体告警'
			},
			{
				name: '温度告警'
			},
			{
				name: '湿度告警'
			},
			{
				name: '震动告警'
			},
			{
				name: '浸水告警'
			},
			{
				name: '故障告警'
			},
			{
				name: "灭火控制告警"
			}
			],
				this.info1()
		} else {
			this.list = [{
				name: 'A相电压告警'
			},
			{
				name: 'B相电压告警'
			},
			{
				name: 'C相电压告警'
			},
			{
				name: 'A相电流告警'
			},
			{
				name: 'B相电流告警'
			},
			{
				name: 'C相电流告警'
			},
			{
				name: '净有功电度告警'
			},
			{
				name: '净无功电度告警'
			},
			{
				name: '系统有功功率告警'
			},
			{
				name: '系统无功功率告警'
			},
			{
				name: '系统功率因素'
			},
			{
				name: '开闸告警'
			}
			]
			this.info()
		}
	},
	methods: {
		back() {
			this.$router.go(-1); //返回上一层
		},
		edhj() {

			if (this.type == 0) {
				this.$ajax('edhj', 'post', this.Hjdata).then(res => {

					if (res.code == 2000) {
						this.info1()
						// this.dialogFormVisible = false
						this.$message({
							type: 'success',
							message: res.message
						});
					} else {
						this.$message({
							type: 'error',
							message: res.message
						});
					}
				})
			} else {
				this.$ajax('edny', 'post', this.Hjdata).then(res => {
					console.log(res);
					if (res.code == 2000) {
						this.info()
						// this.dialogFormVisible = false
						this.$message({
							type: 'success',
							message: res.message
						});
					} else {
						this.$message({
							type: 'error',
							message: res.message
						});
					}
				})
			}
		},
		Form() {
			console.log(this.form, ' this.form!!!');
			this.$ajax('addgjry', 'post', this.form).then(res => {

				if (res.code == 2000) {
					this.fdry()
					this.dialogFormVisible = false
					this.$message({
						type: 'success',
						message: res.message
					});
				} else {
					this.$message({
						type: 'error',
						message: res.message
					});
				}
			})
		},
		Adgj() {

			this.form = {
				"personnel": '',
				"phone": ''
			}
			this.dialogFormVisible = true
			this.newad = true
		},
		Edgj(item) {

			this.form = {
				"id": item.id,
				"personnel": item.personnel,
				"phone": item.phone
			}
			this.dialogFormVisible = true
			this.newad = false
		},
		Form1() {
			console.log(this.form, ' this.form!!!');
			this.$ajax('edgjry', 'post', this.form).then(res => {

				if (res.code == 2000) {
					this.fdry()
					this.dialogFormVisible = false
					this.$message({
						type: 'success',
						message: res.message
					});
				} else {
					this.$message({
						type: 'error',
						message: res.message
					});
				}
			})
		},
		del(id) {
			this.$ajax('delgjry', 'delete', id).then(res => {
				console.log(res);
				if (res.code == 2000) {
					this.fdry()
					this.$message({
						type: 'success',
						message: res.message
					});
				} else {
					this.$message({
						type: 'error',
						message: res.message
					});
				}
			})
		},
		To() {
			this.$router.push('/gjsz_4')
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		},
		xian(item) {
			item.qr = !item.qr
		},
		fdry() {
			this.$ajax('fdgjry', 'get', '').then(res => {
				console.log(res, 'fdry()!!!');
				this.FData = res.data
				// this.Data = res.data.records
				// this.total = res.total

			})
		},
		info() {
			this.$ajax('nyjg', 'get', this.id).then(res => {
				console.log(res);
				this.Hjdata = res.data
				// this.Data = res.data.records
				// this.total = res.total

			})
		},
		info1() {
			this.$ajax('hjjg', 'get', this.id).then(res => {
				console.log(res);
				this.Hjdata = res.data
				// this.Data = res.data.records
				// this.total = res.total

			})
		}
	},
}
</script>

<style lang="scss" scoped>
.m1 {
	position: relative;
	color: #4a4a4a;
	margin: 1rem 0 0 .3rem;
	width: 97rem;
	// border: 1px solid #00FFFF;

	// height: 59.4rem;

	.hd {
		position: relative;
		width: 100%;
		height: 3rem;


		.h1 {
			display: inline-block;
			// width: 11.5rem;
			height: 3rem;
			line-height: 3rem;
			padding-left: 1rem;
			font-size: 1.4rem;
		}

		.h2 {
			position: absolute;
			top: 1rem;
			right: 2rem;
			width: 11.5rem;
			height: 4.2rem;
			background-color: rgba(43, 201, 222, 1);
			line-height: 4.2rem;
			text-align: center;
			border-radius: .5rem;
			cursor: pointer;
		}
	}


	.lb {
		position: relative;
		// border: 1px solid #2BDEC9;
		margin-top: 1rem;
		font-size: 0.9rem;
		// overflow: auto;

		.hd {
			width: 100%;
			// height: 5rem;
			line-height: 3rem;
			// padding-left: 1rem;
			font-size: 0.9rem;
			// background-color: rgba(43, 222, 201, 0.6);

			// border-bottom: 1px solid #2BDEC9;
			.hd_1 {
				display: inline-block;
				width: 5%;
				height: 3rem;
				line-height: 3rem;
				text-align: center;
				margin-right: .5%;
				// background-color: rgba(43, 201, 222, 0.6);
			}

			.hd_2 {
				display: inline-block;
				width: 100%;
				height: 3rem;
				line-height: 3rem;
				background-color: rgba(162, 162, 162, 0.1);

				// background-color: rgba(43, 201, 222, 0.6);
				.hd_2_1 {
					position: absolute;
					left: 2rem;
					top: 50%;
					transform: translateY(-50%);
				}

				.hd_2_2 {
					margin-left: 31.8rem;
					width: 27.4rem;
					height: 3rem;

					.d1 {
						display: inline-block;
						margin-top: .0rem;
						border-radius: .5rem;
						width: 8rem;
						height: 2rem;
						border: 1px solid rgba(255, 255, 255, .6);
						box-sizing: border-box;
						text-align: center;
						line-height: 2rem;
					}

					.d2 {
						display: inline-block;
						margin-top: .0rem;
						border-radius: .5rem;
						width: 8rem;
						height: 2rem;
						border: 1px solid rgba(255, 255, 255, .6);
						box-sizing: border-box;
						text-align: center;
						line-height: 2rem;
					}
				}

				.img {
					position: absolute;
					top: 50%;
					right: 3rem;
					transform: translateY(-50%);
					width: 3rem;
					height: 3rem;
					cursor: pointer;
				}
			}

			.zh {
				margin-left: 1rem;
				display: inline-block;
				width: 10.8rem;
				overflow: hidden;
			}
		}

		.hd:nth-child(2n) {
			background-color: rgba(14, 242, 242, 0.3);
		}

		.hd1 {
			position: relative;
			width: 100%;
			height: 3rem;


			.h1 {
				display: inline-block;
				// width: 11.5rem;
				height: 3rem;
				line-height: 3rem;
				padding-left: 1rem;
				font-size: 1.2rem;
			}

			.h2 {
				position: absolute;
				top: 1rem;
				right: 2rem;
				width: 7rem;
				height: 3rem;
				background-color: rgba(43, 201, 222, 1);
				line-height: 3rem;
				text-align: center;
				border-radius: .5rem;
				cursor: pointer;
			}
		}

		.qx {
			position: absolute;
			top: .1rem;
			left: 50rem;
			display: inline-block;

			.lst {
				display: inline-block;
				width: 6.6rem;
				height: 2.6rem;
				line-height: 2.6rem;
				background-color: rgba(43, 201, 222, 0.6);
				text-align: center;
				border-radius: .3rem;
				margin-right: 1rem;
			}
		}

		.xg {
			position: absolute;
			top: .5rem;
			right: 2rem;
			width: 27rem;
			height: 2.6rem;
			line-height: 2.6rem;

			.gg {
				display: inline-block;
				width: 6.6rem;
				height: 2.6rem;
				line-height: 2.6rem;
				text-align: center;
				background-color: rgba(28, 184, 184, 1);
				border-radius: .4rem;
				margin-left: 1rem;
			}
		}
	}

	.main {
		position: relative;
		width: 100%;
		// height: 4.2rem;
		margin-top: 1rem;

		.m_1 {
			display: inline-block;
			width: 5%;
			height: 4.2rem;
			line-height: 4.2rem;
			text-align: center;
			margin-right: .5%;
			border: 1px solid rgba(43, 201, 222, 0.6);
			box-sizing: border-box;
		}

		.m_2 {
			position: relative;
			display: inline-block;
			width: 94.5%;
			height: 4.2rem;
			line-height: 4.2rem;
			border: 1px solid rgba(43, 201, 222, 0.6);
			box-sizing: border-box;
		}

		.m_3 {
			position: relative;
			box-sizing: border-box;
			float: right;
			width: 94.5%;
			height: 4.2rem;
			border: 1px solid rgba(43, 201, 222, 0.6);
			box-sizing: border-box;
			margin-top: .5rem;
			line-height: 4.2rem;

			.zh {
				margin-left: 1rem;
				display: inline-block;
				width: 16.8rem;
				overflow: hidden;
			}

			.qx {
				position: absolute;
				top: .1rem;
				left: 50rem;
				display: inline-block;

				.lst {
					display: inline-block;
					width: 6.6rem;
					height: 2.6rem;
					line-height: 2.6rem;
					background-color: rgba(43, 201, 222, 0.6);
					text-align: center;
					border-radius: .3rem;
					margin-right: 1rem;
				}
			}

			.xg {
				position: absolute;
				top: .5rem;
				right: 2rem;
				width: 27rem;
				height: 2.6rem;
				line-height: 2.6rem;

				.gg {
					display: inline-block;
					width: 6.6rem;
					height: 2.6rem;
					line-height: 2.6rem;
					text-align: center;
					background-color: rgba(28, 184, 184, 1);
					border-radius: .4rem;
					margin-left: 1rem;
				}
			}
		}

		.m_3:last-child {
			margin-bottom: 1rem;
		}
	}
}

.back {
	position: absolute;
	top: 1rem;
	right: 2rem;
	border: 1px solid #1CB8B8;
	border-radius: 0.5rem;
	width: 5rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	color: rgba(28, 184, 184, 1);
	font-size: 1rem;
	margin-right: 2rem;
	cursor: pointer;
}

.back {
	position: absolute;
	top: 1rem;
	right: 2rem;
	border: 1px solid #409eff;
	border-radius: 0.5rem;
	width: 5rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	color: #409eff;
	font-size: 0.9rem;
	margin-right: 2rem;
	cursor: pointer;
}
</style>
